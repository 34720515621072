import {useCustomSearchStore} from "~/stores/customSearchStore";

export default function useCustomSearchVisibility() {
    const customSearchStore = useCustomSearchStore();
    let observer: IntersectionObserver | null = null;

    const observeElement = (element: HTMLInputElement|null) => {
        if (element) {
            observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    customSearchStore.setIsVisible(entry.isIntersecting);
                });
            });

            observer.observe(element);
        }
    }

    const disconnectObserver = () => {
        if (observer) {
            observer.disconnect();
        }
        customSearchStore.setIsVisible(false)
    }

    const isCustomSearchVisible = () => customSearchStore.isVisible;


    return {
        isCustomSearchVisible,
        observeElement,
        disconnectObserver
    }
}
