import { defineStore } from 'pinia';

export const useCustomSearchStore = defineStore('customSearch', {
    state: () => ({
        isVisible: false,
    }),

    actions: {
        setIsVisible(value: boolean) {
            this.isVisible = value;
        },
    },
});
